* {
    color: white;
    font-family: "Roboto", sans-serif;
    line-height: 1.5;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  button,
  input,
  select,
  textarea {
    background-color: #121212;
    border: 2px solid white;
    border-radius: 7px;
    font: inherit;
  }
  .full{
    width:100%;
    height: 100%;
    position: absolute;
  }
  *{margin:0;padding:0;}html{width:100%;height:100%}